import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/who-we-are-section1";
import Section6 from "../sections/index-section5-multiple-use";
import Section8 from "../sections/new-themed-mentorship-section3-multiple-use";
import SEO from "../components/seo"

export default function WhoWeAre() {
  return (
    <Layout 
      LogoColor={props => props.theme.colors.white} 
      HeaderMenuColor={props => props.theme.colors.white} 
      firstSectionBGColor={props => props.theme.colors.secondary_green}

      //button
      HeaderButtonColor={props => props.theme.buttons.secondary_small_button_white_green.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.secondary_small_button_white_green.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.secondary_small_button_white_green.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.secondary_small_button_white_green.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.secondary_small_button_white_green.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.secondary_small_button_white_green.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.secondary_small_button_white_green.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.secondary_small_button_white_green.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.secondary_small_button_white_green.pressed.background_color}
    >
      <SEO title={"Who We Are"} description={"WomenWorks is the place for women to connect, empower, and reach their fullest potential. We are bad-ass. Connected. Financially independent."} />
      <Section1></Section1>
      <Section8
        BackgroundLeft = {props => props.theme.colors.accent_yellow70}
        BackgroundRight = {props => props.theme.colors.white_dark}
        TextColor = {props => props.theme.colors.gray_dark}

        TitleLeft = "Volunteer and be part of the team"
        ContentLeft = "Mentorship and access to WomenWorks LadyBosses network could benefit women in different stages of professional career."

        TitleRight= "Partnership with WomenWorks"
        ContentRight = "If you believe in a vision to empower the world through women and would like to collaborate with us, we'd love to hear from you."

        ButtonTextLeft = "Contact us"
        ButtonLinkLeft = "mailto:yours@womenworks.io"
        ButtonTextRight = "Give us a shout"
        ButtonLinkRight = "mailto:yours@womenworks.io"

        ButtonColorLeft={props => props.theme.buttons.primary_button_violet.normal.color} 
        ButtonBorderLeft={props => props.theme.buttons.primary_button_violet.normal.border_color} 
        ButtonBackgroundLeft={props => props.theme.buttons.primary_button_violet.normal.background_color}
        
        ButtonColorHoverLeft={props => props.theme.buttons.primary_button_violet.hovered.color}
        ButtonBorderHoverLeft={props => props.theme.buttons.primary_button_violet.hovered.border_color} 
        ButtonBackgroundHoverLeft={props => props.theme.buttons.primary_button_violet.hovered.background_color}

        ButtonColorPressedLeft={props => props.theme.buttons.primary_button_violet.pressed.color}
        ButtonBorderPressedLeft={props => props.theme.buttons.primary_button_violet.pressed.border_color} 
        ButtonBackgroundPressedLeft={props => props.theme.buttons.primary_button_violet.pressed.background_color}

        ButtonColorRight={props => props.theme.buttons.primary_button_violet.normal.color} 
        ButtonBorderRight={props => props.theme.buttons.primary_button_violet.normal.border_color} 
        ButtonBackgroundRight={props => props.theme.buttons.primary_button_violet.normal.background_color}
        
        ButtonColorHoverRight={props => props.theme.buttons.primary_button_violet.hovered.color}
        ButtonBorderHoverRight={props => props.theme.buttons.primary_button_violet.hovered.border_color} 
        ButtonBackgroundHoverRight={props => props.theme.buttons.primary_button_violet.hovered.background_color}

        ButtonColorPressedRight={props => props.theme.buttons.primary_button_violet.pressed.color}
        ButtonBorderPressedRight={props => props.theme.buttons.primary_button_violet.pressed.border_color} 
        ButtonBackgroundPressedRight={props => props.theme.buttons.primary_button_violet.pressed.background_color}
      ></Section8>
      <Section6 
        DisplaySection='block'
      >
      </Section6>
    </Layout>   
  )
}
